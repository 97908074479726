<template>
<div class="p-contact">
<div class="p-contact-content">
	<div class="p-contact-title g-texta-c">联系我们</div>
	<div class="p-contact-subtitle g-fonts-18 g-fontw-600 g-letters-22 g-color-light g-texta-c">CONTACT US</div>
	<a-divider style="margin-top: 40px; margin-bottom: 60px"/>
	<a-form-model ref="form" layout="vertical" :model="form" :rules="rules">
		<a-row :gutter="100" >
			<a-col :span="12">
				<a-form-model-item label="联系人" prop="name">
					<a-input v-model="form.name" size="large"/>
				</a-form-model-item>
			</a-col>
			<a-col :span="12">
				<a-form-model-item label="手机号码" prop="phone">
					<a-input v-model="form.phone" size="large"/>
				</a-form-model-item>
			</a-col>
			<a-col :span="12">
				<a-form-model-item label="公司名称" prop="company">
					<a-input v-model="form.company" size="large"/>
				</a-form-model-item>
			</a-col>
			<a-col :span="12">
				<a-form-model-item label="试用产品" prop="product">
					<a-input v-model="form.product" size="large"/>
				</a-form-model-item>
			</a-col>
			<a-col :span="24">
				<a-form-model-item label="备注" prop="remark">
					<div class="g-position-r">
						<a-textarea v-model="form.remark" size="large" :auto-size="{ minRows: 4 }"/>
						<div class="g-position-a g-color-light g-letters-17 g-right-11 g-bottom-11">
							{{currentLen}}/{{limit}}
						</div>
					</div>
				</a-form-model-item>
			</a-col>
		</a-row>
	</a-form-model>
	<div class="g-fonts-12 g-texta-c g-color-light g-letters-14 g-position-r" style="top: -20px">提交成功后，我们会尽快与您联系，请保证信息正确有效</div>
	<div class="p-contact-submit g-texta-c">
		<a-button type="primary" shape="round" size="large" @click="onSubmit">提交</a-button>
	</div>
	 <a-modal v-model="visible" centered width="700px" title="Title" on-ok="handleOk" dialogClass="p-contact-dialog">
		<div class="g-texta-c p-contact-tip">
			<img :src="tipImg"/>
			<div class="g-fonts-16 g-letters-19 g-marginb-15">{{tipMsg}}</div>
			<div class="g-color-light g-letters-17">{{tipMsg2}}</div>
		</div>
		<template slot="footer">
			<a-button v-if="success" style="width: 250px" shape="round" ghost key="submit" size="large" type="primary"  @click="handleOk">
				{{time}}s  返回首页
			</a-button>
			<a-button v-else style="width: 250px" key="back" shape="round" ghost size="large" type="primary" @click="handleCancel">返回重新填写</a-button>
		</template>
    </a-modal>
</div>
</div>
</template>
<script>
import Qs from 'qs'
export default {
	name: 'Contact',
	data() {
		return {
			visible: false,
			limit: 500,
			time: 0,
			form: {
				name: '',
				phone: '',
				company: '',
				product: '',
				remark: '',
			},
			rules: {
				name: [{ required: true, message: '请输入联系人' }],
				phone: [
					{ required: true, message: '请输入手机号码' },
					{
						pattern: /^1[3-9]\d{9}$/,
						message: '请输入正确的手机号码'
					}
				],
				remark: [{ max: 500, message: '最多500个字符' }]
			},
			success: false,
			timeoutId: null
		}
	},
	activated() {
		this.$refs.form.resetFields()
		this.fillProduct()
	},
	computed: {
		currentLen() {
			return typeof this.form.remark === 'string' ? this.form.remark.length : 0
		},
		tipImg() {
			return this.success ? require('@/assets/img/contact/contact-right.svg') : require('@/assets/img/contact/contact-wrong.svg')
		},
		tipMsg() {
			return this.success ? '信息提交成功！' : '信息提交失败'
		},
		tipMsg2() {
			return this.success ? '我们会尽快与您联系，请保持手机畅通！' : '可能是因为网络原因，请检查您的网络…'
		}
	},
	mounted() {
		this.fillProduct()
	},
	methods: {
		fillProduct() {
			const { query } = this.$route
			this.form.product = query.t || ''
		},
		validate() {
			return new Promise((resolve, reject) => {
				this.$refs.form.validate(valid => {
					if (valid) {
						resolve()
					} else {
						reject(new Error('校验失败'))
					}
				})
			})
		},
		async onSubmit() {
			try {
				await this.validate()
				try {
					await this.$request({
						url: '/sd/contact/hit',
						method: 'post',
						data: Qs.stringify({ ...this.form }),
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
						}
					})
					this.time = 3
					this.success = true
					this.visible = true
					this.timeout()
				} catch (e) {
					this.success = false
					this.visible = true
				}
			} catch (e) {
				console && console.log('e', e)
			}
		},
		timeout() {
			if (this.time <= 0) {
				this.visible = false
				return this.$router.push('/')
			}
			this.time -= 1
			this.timeoutId = setTimeout(this.timeout, 1000)
		},
		handleCancel() {
			this.visible = false
		},
		handleOk() {
			if (this.visible === false) {
				return
			}
			clearTimeout(this.timeoutId)
			this.visible = false
			this.$router.push('/')
		}

	}
}
</script>
<style lang="less" scoped>
@import "./Contact.less";
</style>
<style lang="less">
.p-contact {
    .ant-form-item-label {
        padding: 0 0 18px !important;
    }
}
.p-contact-dialog {
	.ant-modal-header {
		border: none;
		padding-top: 80px;
		.ant-modal-title {
			display: none;
		}
	}
	.ant-modal-body {
		padding: 0;
	}
	.ant-modal-footer {
		border: none;
		padding: 0;
		padding-bottom: 60px;
		text-align: center;
	}
}
</style>
